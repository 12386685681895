<template lang="pug">
div.header
  .main
    .nav
      h1
        a.logo(href="/")
      span.nav-item.flex-center.bold(v-for="(nav, navIdx) in navs" :key="navIdx" :to="nav.path" @click="handleNav(nav)") {{ nav.name }}
  z-cooperation(:open.sync="showContact")
</template>
<script>
export default {
  data() {
    return {
      navs: [
        { name: "服务", path: "/home" },
        { name: "产业领域", path: "/about" },
        { name: "公司", path: "/about" },
        { name: "联系", path: "/about", code: 'contact' },
      ],
      showContact: false,
    }
  },
  methods: {
    handleNav(nav) {
      if (nav.code == 'contact') {
        this.showContact = true
        return
      }
      this.$router.replace({ path: nav.path })
    }
  }
}
</script>
<style lang="stylus" scoped>
.header
  height 50px
  background #2B303B
.main
  height 100%
  background #2B303B
.nav
  height 100%
  font-size 14px
  display flex;
  align-items center
  justify-content flex-end
  position relative
  cursor pointer
  h1,
  h1 a.logo
    position absolute
    width 150px
    height 50px
    background url("../assets/images/logo.png") no-repeat center
    background-size cover
    left 0
    top 50%
    transform translateY(-50%)
    margin 0
  &-item
    transition .2s all ease
    height 100%
    padding 0 15px
    color #fff
    user-select none
    &:hover,
    &.router-link-exact-active
      color #3AD682
</style>