<template lang="pug">
transition(name="fade")
  .overlayer(v-if="open")
    .mask
    .overlayer-inner
      div.icon-close(@click="handleClose")
        z-icon(name="icon-tuite")
      h3 合作机会
      .desc 
        p 一封邮件或许正是成就一个出色产品的开端。
        p 欢迎填写表格或发送合作邮件至:
          a(href="Mailto:contace@zhisoft.com.cn") contace@zhisoft.com.cn
      form
        .form-item.requied
          .form-item-inner
            span 您的姓名：
            input
        .form-item.requied
          .form-item-inner
            span 您的公司：
            input
        .form-item.requied
          .form-item-inner
            span 您的邮箱：
            input
        .form-item.requied
          .form-item-inner
            span 您的电话：
            input
        .form-item
          .form-item-inner
            span 您的电话：
            input
        .form-item
          .form-item-inner
            span 公司网站：
            input
        .form-item
          .form-item-inner
            span 所在城市：
            input
        .form-item
          .form-item-inner
            span 所属行业：
            input
        .form-item
          .form-item-inner
            span 期望开始时间：
            input
        .form-item-textarea
          textarea(placeholder="合作需求：产品、设备、功能的描述或任何帮助我们了解您项目状态和需求的信息，我们将在72小时内通过您留下的联系方式与您取得联系，谢谢！")
        .btn-send.flex-center 完成并发送
        .contact 
          |媒体联系:
          a(href="Mailto:hi@zhisoft.com.cn") hi@zhisoft.com.cn

</template>

<script>
export default {
  name: "cooperation",
  props: {
    open: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    open(newValue) {
      const bodyStyle = document.querySelector('body')
      if (newValue) {
        bodyStyle.style.height = '100vh'
        bodyStyle.style.overflow = 'hidden'
      } else {
        bodyStyle.style = ""
      }
    }
  },
  methods: {
    handleClose() {
      this.show = false
      this.$emit('update:open', false)
    }
  },
}
</script>
<style lang="stylus" scoped>
.overlayer
  position relative
  z-index 100
  &-inner
    background-color #fff
    width 830px
    padding 20px
    position fixed
    top 50%
    left 50%
    transform translate3d(-50%, -50%, 0)
    z-index 1000
    .icon-close
      position absolute
      top 15px
      right 15px
      font-size 30px
      color #666
      cursor pointer
    h3,
    .desc
      text-align center
    h3
      font-size 26px
      color #595959
      margin-bottom 20px
    p
      padding 0
      margin 0
    .desc
      font-size 19px
      color #999
      line-height 1.4
      margin-bottom 20px
      a
        color #3AD682

  .mask
    position fixed
    width 100%
    height 100%
    top 0
    left 0
    background: rgba(0, 0, 0, 0.3);
    z-index 999
  .form-item
    display inline-block
    width 50%
    margin-bottom 15px
    &:nth-child(even)
      padding-left 10px
    &:nth-child(odd)
      padding-right 10px
    &.requied
      .form-item-inner::after
        content "*"
        position absolute
        top 50%
        right 10px
        color red
        transform translateY(-50%)
      
    &-inner
      position relative
      border 2px solid #EBEBEB
      display flex
      align-items center
      padding 0 10px
      input
        width 100%
        height 32px
        border none
        flex 1
        outline none
        position relative
        z-index 1
        background-color transparent
        padding-left 5em
        color #666
      span
        font-size 14px
        color #999
        position absolute
    &-textarea
      border 2px solid #EBEBEB
      padding 10px
      textarea
        width 100%
        border none
        outline none
        resize none
        height 50px
        color #666
        &::placeholder
          font-size 14px
          color #999
          line-height 1.4

  .btn-send
    width 484px
    height 48px
    color #fff
    background-color #3AD682
    border 4px
    margin 20px auto
    cursor pointer
    transition .2s all ease
    user-select none
    &:active
      background-color #349561
.contact
  text-align center
  color: #999
  font-size #666
  a
    color: #3AD682

.fade-enter-active, .fade-leave-active
  transition: opacity .3s;
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>