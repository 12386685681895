<template lang="pug">
div
  Header
  router-view
  Footer
</template>
<script>
import Header from '@/layout/header'
import Footer from '@/layout/footer'

export default {
  components: {
    Header,
    Footer,
  },
}
</script>
