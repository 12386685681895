import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'

import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style (>= Swiper 6.x)
// import 'swiper/swiper-bundle.css'

// import style (<= Swiper 5.x)
import 'swiper/dist/css/swiper.min.css'

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

import 'normalize.css/normalize.css'

// assets
import '@/assets/styles/global.styl'
import '@/assets/iconfont'

import zIcon from "@/components/z-icon"
import cooperation from "@/components/cooperation"

Vue.component('z-icon', zIcon)
Vue.component('z-cooperation', cooperation)

Vue.config.productionTip = false

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')
