<template lang="pug">
.footer
  .main
    .back-top.clearfix
      div.back-top-inner(@click="handleScrollToTop")
        span 回到顶部
        z-icon.icon(name="icon-tuite")
    .footer-content.clearfix
      .footer-content-column.left
        img(src="@/assets/images/logo_footer.png")
        div
          text
            z-icon.icon(name="icon-facebook")
          text
            z-icon.icon(name="icon-tuite")
      .footer-content-column.service-content
        li
          span 服务内容
        li
          span 软件开发
        li
          span 移动应用开发
        li
          span IT咨询与服务
        li
          span Ai创意营销
        li
          span 物联网
        li
          span 用户体验设计
      .footer-content-column
        li
          span 服务领域
        li
          span 能源
        li
          span 互联网
        li
          span 财务
        li
          span 建筑
        li
          span 制造业
        li
          span 生物医药
      .footer-content-column.right
        ul
          li
            span 联系
          li
            span 使用条款
          li
            span 隐私政策
        img.qrcode(src="@/assets/images/icon_qrcode.jpg")
    p.desc
      span 北京小枝科技有限公司
      a(target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index")  京ICP备2021021944号
      a(target="_blank" href="http://www.beian.gov.cn/portal/index")  公安备0983874744932
</template>
<script>
export default {
  methods: {
    handleScrollToTop() {
      var scrollTopSmooth = function (position) {
        // 不存在原生`requestAnimationFrame`，用`setTimeout`模拟替代
        if (!window.requestAnimationFrame) {
          window.requestAnimationFrame = function (cb) {
            return setTimeout(cb, 27);
          };
        }
        // 当前滚动高度
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        // step
        var step = function () {
          var distance = position - scrollTop;
          scrollTop = scrollTop + distance / 5;
          if (Math.abs(distance) < 1) {
            window.scrollTo(0, position);
          } else {
            window.scrollTo(0, scrollTop);
            requestAnimationFrame(step);
          }
        };
        step();
      }

      scrollTopSmooth(0);
    },
  },
}
</script>
<style lang="stylus" scoped>
.main
  background-color #2F3033
.footer
  ul,
  li
    padding 0
    margin 0
    list-style-type none
  background-color #2F3033
  padding 0 50px
  .back-top
    padding 30px 0
    color #ACB0B1
    font-size 12px
    &-inner
      cursor pointer
      float right
      &:hover
        color #fff
      .icon
        margin-left 5px
  &-content
    &-column
      float left
      &.service-content
        width 380px
      li
        color #ACB0B1
        padding 5px 0
        cursor pointer
        span
          transition .2s all ease
          &:hover
            color: #fff
      &.right
        float right
        ul
          margin-bottom 35px
          li
            padding 5px
            color #ACB0B1
            font-size 14px
      &.left
        width 480px
        img
          width 90px
          margin-bottom 135px
        .icon
          font-size 20px
          margin-right 15px
  .desc
    text-align center
    font-size 12px
    margin 0
    padding 55px 0 25px
    & *
      color #ACB0B1
      transition .2s all ease
      margin 0 5px
      &+a:hover
        color: #fff
  .qrcode
    width 80px
    height 80px
</style>